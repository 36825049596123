@font-face {
    font-family: 'lackregular';
    src: url('fonts/Lack-Regular.ttf');
    /*src: url('fonts/lack-regular-webfont.woff2') format('woff2'),
         url('fonts/lack-regular-webfont.woff') format('woff');*/
    font-weight: normal;
    unicode-range: U+?????;
}

@font-face {
    font-family: 'lackitalic';
    src: url('fonts/Lack-Italic.ttf');
    /*src: url('fonts/lack-italic-webfont.woff2') format('woff2'),
         url('fonts/lack-italic-webfont.woff') format('woff');*/
    font-weight: normal;
    font-style: normal;
    unicode-range: U+?????;
}

@font-face {
    font-family: 'lack_lineregular';
    src: url('fonts/Lack-Line-Regular.ttf');
    /*src: url('fonts/lack-line-regular-webfont.woff2') format('woff2'),
         url('fonts/lack-line-regular-webfont.woff') format('woff');*/
    font-weight: normal;
    font-style: normal;
    unicode-range: U+?????;
}

@font-face {
    font-family: 'lack_lineitalic';
    src: url('Lack-Line-Italic.ttf');
    /*src: url('fonts/lack-line-italic-webfont.woff2') format('woff2'),
         url('fonts/lack-line-italic-webfont.woff') format('woff');*/
    font-weight: normal;
    font-style: normal;
    unicode-range: U+?????;

}
