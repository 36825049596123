@import "reset.scss", "font.scss";

*{
  --mainColor: #EDEEE0;
  --headerHeight: 14%;
  --margin: 30px;
}

body{
  background-color: var(--mainColor);
  font-family: 'lackregular';
}

a{
  text-decoration: underline;
}

mark{
  background-color: black;
  color: white;
  padding: 0 0.1em;
}
